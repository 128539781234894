import React from 'react'
import styled from 'styled-components'
import { Sun, Moon } from 'react-feather'
import { useDarkModeManager } from '../../contexts/LocalStorage'
import { MdBrightness2, MdWbSunny } from 'react-icons/md'

const IconWrapper = styled.div<{ isActive?: boolean }>`

  :hover {
    opacity: 1;
  }
`

const StyledToggle = styled.div`
  display: flex;
  width: fit-content;
  cursor: pointer;
  text-decoration: none;
  color: white;

  :hover {
    text-decoration: none;
  }
`

export interface ToggleProps {
  isActive: boolean
  toggle: () => void
}

export default function Toggle({ isActive, toggle }: ToggleProps) {

  const [isDark] = useDarkModeManager();

  return (
    <StyledToggle onClick={toggle}>
      {isDark ? (
        <span>
          <IconWrapper isActive={isActive}>
            <MdBrightness2 size={24} />
          </IconWrapper>
        </span>
      ) : (
        <span>
          <IconWrapper isActive={!isActive}>
            <MdWbSunny size={24} color="black" />
          </IconWrapper>
        </span>
      )}
    </StyledToggle>
  )
}
