import styled from "styled-components";

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.drawerBackgroundColor};
  width: 100%;
  height: 20rem;
  padding: 2rem 0rem;
  position: fixed;
  bottom: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  z-index: 9999;
  box-shadow: 0px 5px 21px 0px rgba(0, 0, 0, 0.75);

  @media (max-width: 425px) {
    width: 100%;
  }

  a {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    text-decoration: none;
    transition: color 0.3s linear;
    color: ${({ theme }) => theme.blackWhite};

    @media (max-width: 425px) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: white;
    }
  }
`;
