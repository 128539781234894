export const FACTORY_ADDRESS = '0x7Bbbb6abaD521dE677aBe089C85b29e3b2021496'

export const BUNDLE_ID = '1'

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  // THREE_MONTHS: '3 months',
  // YEAR: '1 year',
  HALF_YEAR: '6 months',
  ALL_TIME: 'All time',
}

// token list urls to fetch tokens from - use for warnings on tokens and pairs
export const SUPPORTED_LIST_URLS__NO_ENS = [
  'https://raw.githubusercontent.com/Pollum-io/pegasys-tokenlists/master/pegasys.tokenlist.json'
]

// hide from overview list
export const TOKEN_BLACKLIST = [
]

// pair blacklist
export const PAIR_BLACKLIST = [
]

// warnings to display if page contains info about blocked token
export const BLOCKED_WARNINGS = {
}

/**
 * For tokens that cause erros on fee calculations
 */
export const FEE_WARNING_TOKENS = []

export const UNTRACKED_COPY = 'Derived USD values may be inaccurate without liquid stablecoin or SYS pairings.'

// pairs that should be tracked but arent due to lag in subgraph
export const TRACKED_OVERRIDES_PAIRS = [
]

// tokens that should be tracked but arent due to lag in subgraph
// all pairs that include token will be tracked
export const TRACKED_OVERRIDES_TOKENS = [
  '0xd3e822f3ef011ca5f17d82c956d952d8d7c3a1bb', // WSYS
  '0xe18c200a70908c89ffa18c628fe1b83ac0065ea4', // PSYS
  '0x71319ee6f41598412701f0d3e987bb1d74921fb3', // WBTC
  '0x15dc8168aa893ee5abf46778ae99fd7ed9225914', // WETH
  '0x3fca6743e2fb55759fee767f3a68b2c06d699dc4', // USDC
  '0xfe668a3d6f05e7799aae04659fc274ac00d094c0', // USDT
  '0x5b7f5628814c2e5d31c11d10cfe27c5cbcb174f7', // DAI
]
