import React from "react";
import { StyledMenu } from "./styled";
import { bool } from "prop-types";
import styled from "styled-components";
import { FiTrendingUp } from "react-icons/fi";
import { MdOutlineGeneratingTokens } from "react-icons/md";
import { RiPieChartLine, RiListUnordered } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { useDarkModeManager } from "../../contexts/LocalStorage";

const LinkComponent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 55px;
`;

const IconOverview = styled(FiTrendingUp)`
  padding-right: 10px;
  width: 20px;
  height: 20px;
`;

const IconTokens = styled(MdOutlineGeneratingTokens)`
  padding-right: 10px;
  width: 20px;
  height: 20px;
`;

const IconPairs = styled(RiPieChartLine)`
  padding-right: 10px;
  width: 20px;
  height: 20px;
`;

const IconAccounts = styled(RiListUnordered)`
  padding-right: 10px;
  width: 20px;
  height: 20px;
`;

const NavBarLink = styled(NavLink)`
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  height: max-content;
  margin: 0;
  opacity: 0.8;
`;

const Menu = ({ open }) => {
  const [isDark] = useDarkModeManager();

  return (
    <StyledMenu open={open}>
      <LinkComponent>
        <NavBarLink
          to="/home"
          activeStyle={{
            background: isDark ? "#0B172C" : "#F7FAFC",
            color: isDark ? "#FFFFFF" : "#665EE1",
            borderRadius: "71px",
            opacity: "1",
          }}
        >
          <IconOverview />
          Overview
        </NavBarLink>
        <NavBarLink
          to="/tokens"
          activeStyle={{
            background: isDark ? "#0B172C" : "#F7FAFC",
            color: isDark ? "#FFFFFF" : "#665EE1",
            borderRadius: "71px",
            opacity: "1",
          }}
        >
          <IconTokens />
          Tokens
        </NavBarLink>
        <NavBarLink
          to="/pairs"
          activeStyle={{
            background: isDark ? "#0B172C" : "#F7FAFC",
            color: isDark ? "#FFFFFF" : "#665EE1",
            borderRadius: "71px",
            opacity: "1",
          }}
        >
          <IconPairs />
          Pairs
        </NavBarLink>
        <NavBarLink
          to="/accounts"
          activeStyle={{
            background: isDark ? "#0B172C" : "#F7FAFC",
            color: isDark ? "#FFFFFF" : "#665EE1",
            borderRadius: "71px",
            opacity: "1",
          }}
        >
          <IconAccounts />
          Accounts
        </NavBarLink>
      </LinkComponent>
    </StyledMenu>
  );
};

Menu.propTypes = {
  open: bool.isRequired,
};

export default Menu;
