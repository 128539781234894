import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useDarkModeManager } from "../../contexts/LocalStorage";
import Toggle from "../Toggle";
import { FiTrendingUp } from "react-icons/fi";
import { MdOutlineGeneratingTokens } from "react-icons/md";
import { RiPieChartLine, RiListUnordered } from "react-icons/ri";
import { BasicLink } from "../Link";
import {
  Link as RouterLink,
  useLocation,
  useHistory,
  NavLink,
} from "react-router-dom";
import Menu from '../Menu/index';
import Burguer from '../Burguer/index';
import { useOnClickOutside } from "../../hooks";
import PinnedData from "../PinnedData";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 14px;
  align-items: center;
  gap: 545px;
  position: relative;
  z-index: 9999;
  width: max-content;

  @media screen and (max-width: 2560px) {
    gap: 750px;
  }

  @media screen and (max-width: 2160px) {
    gap: 650px;
  }

  @media screen and (max-width: 1960px) {
    gap: 550px;
  }

  @media screen and (max-width: 1776px) {
    gap: 482px;
  }
  
  @media screen and (max-width: 1775px) {
    gap: 466px;
  }

  @media screen and (max-width: 1696px) {
    gap: 407px;
  }

  @media screen and (max-width: 1649px) {
    gap: 377px;
  }

  @media screen and (max-width: 1542px) {
    gap: 317px;
  }

  @media screen and (max-width: 1435px) {
    gap: 277px;
  }

  @media screen and (max-width: 1355px) {
    gap: 207px;
  }

  @media screen and (max-width: 1220px) {
    gap: 157px;
  }

  @media screen and (max-width: 1115px) {
    gap: 107px;
  }

  @media screen and (max-width: 1037px) {
    gap: 87px;
  }

  @media screen and (max-width: 900px) {
    gap: 47px;
  }

  @media screen and (max-width: 768px) {
    width: 100vw;
    gap: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
  }
`;

const LeftLogo = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 40px;
  transition: 0.3s;

  @media screen and (max-width: 900px) {
    padding-left: 20px;
  }
`;

const LogoText = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.blackWhite};

  @media screen and (max-width: 990px) {
    display: none;
  }
`;

const MiddleMenu = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({ theme }) => theme.headerBg};
  border-radius: 71px;
  height: max-content;


`;

const NavBarLink = styled(NavLink)`
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  color: ${({ theme }) => theme.headerTextColor};  
  height: max-content;
  margin: 0;
  opacity: 0.8;

  &:nth-child(1) {
    padding: 10px 28px 10px 22px;

    :hover {
      border-radius: 71px;
      background-color: ${({ theme }) => theme.headerSelectedBg};
    }
  }

  &:nth-child(2) {
    padding: 10px 20px 10px 20px;
    :hover {
      border-radius: 71px;
      background-color: ${({ theme }) => theme.headerSelectedBg};
    }
  }
  &:nth-child(3) {
    padding: 10px 20px 10px 20px;
    :hover {
      border-radius: 71px;
      background-color: ${({ theme }) => theme.headerSelectedBg};
    }
  }
  &:nth-child(4) {
    padding: 10px 22px 10px 20px;
    :hover {
      border-radius: 71px;
      background-color: ${({ theme }) => theme.headerSelectedBg};
    }
  }



  @media screen and (max-width: 812px) {
    font-size: 14px;  
  }
`;

const IconOverview = styled(FiTrendingUp)`
  padding-left: 5px;
  width: 20px;
  height: 20px;
`;

const IconTokens = styled(MdOutlineGeneratingTokens)`
  padding-left: 5px;
  width: 20px;
  height: 20px;
`;

const IconPairs = styled(RiPieChartLine)`
  padding-left: 5px;
  width: 20px;
  height: 20px;
`;

const IconAccounts = styled(RiListUnordered)`
  padding-left: 5px;
  width: 20px;
  height: 20px;
`;

const ToggleDarkLightMode = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
`;

export function Header({ history, savedOpen, setSavedOpen }) {
  const [isDark, toggleDarkMode] = useDarkModeManager();
  const [link, setLink] = useState("");
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));
  const [open, setOpen] = useState(false);
  const h = useHistory();
  console.log(h.location.pathname, 'aaa')

  return (
    <HeaderContainer ref={node}>
      <LeftLogo>
        <img
          src={require(isDark
            ? "../../assets/logo_pegasys_transparent.svg"
            : "../../assets/PsysWhite.png")}
          alt="loading-icon"
          width="50"
          height="50"
        />
        <LogoText>Analytics</LogoText>
      </LeftLogo>
      <MiddleMenu>
        <NavBar>
          <NavBarLink to="/home" activeStyle={{
            background: isDark ? "#0B172C" : "#665EE1",
            color: isDark ? "" : "#FFFFFF",
            borderRadius: "71px",
            opacity: "1"
          }}>
            Overview <IconOverview />
          </NavBarLink>
          <NavBarLink to="/tokens" activeStyle={{
            background: isDark ? "#0B172C" : "#665EE1",
            color: isDark ? "" : "#FFFFFF",
            borderRadius: "71px",
            opacity: "1"
          }}>
            Tokens <IconTokens />
          </NavBarLink>
          <NavBarLink to="/pairs" activeStyle={{
            background: isDark ? "#0B172C" : "#665EE1",
            color: isDark ? "" : "#FFFFFF",
            borderRadius: "71px",
            opacity: "1"
          }}>
            Pairs <IconPairs />
          </NavBarLink>
          <NavBarLink to="/accounts" activeStyle={{
            background: isDark ? "#0B172C" : "#665EE1",
            color: isDark ? "" : "#FFFFFF",
            borderRadius: "71px",
            opacity: "1"
          }}>
            Accounts <IconAccounts />
          </NavBarLink>
        </NavBar>
      </MiddleMenu>
      <ToggleDarkLightMode>
        <PinnedData open={savedOpen} setSavedOpen={setSavedOpen} />
        <Toggle isActive={isDark} toggle={toggleDarkMode} />
      </ToggleDarkLightMode>
      <Menu open={open} />
      <Burguer open={open} setOpen={setOpen} />
    </HeaderContainer>
  )
}