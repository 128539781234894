import React from "react";
import styled from "styled-components";
import { useDarkModeManager } from "../../contexts/LocalStorage";

const RadialDark = styled.div`
  width: 70%;
  height: 50%;
  background: radial-gradient(ellipse at center, #56BED8, #010101);
  position: fixed;
  right: 20rem;
  top: 0;
  z-index: 1;
  filter: blur(175px);
`;

const LightDark = styled.div`
  width: 70%;
  height: 50%;
  background: radial-gradient(ellipse at center, #68e1ffbe, #e6faff);
  position: fixed;
  right: 20rem;
  top: 0;
  z-index: 1;
  filter: blur(175px);
`;

export function BackgroundBlur() {
  const [isDark, toggleDarkMode] = useDarkModeManager();

  return isDark ? (
    <RadialDark />
  ) : (
    <LightDark />
  )
}