import React from "react";
import styled from "styled-components";
import { useDarkModeManager } from "../../contexts/LocalStorage";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaTelegramPlane } from "react-icons/fa";
import { SiDiscord } from "react-icons/si";
import { RiGithubFill } from "react-icons/ri";
import { BasicLink } from "../Link";
import { useSessionStart } from "../../contexts/Application";

const FooterContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  gap: 359px;  
  padding-bottom: 60px;
  padding-top: 56px;
  z-index: 999;
  align-items: center;
  background-color: ${({ theme }) => theme.whiteBlack};

  @media screen and (min-width: 320px) {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 0px;
    width: 100vw;
  }

  @media screen and (min-width: 375px) {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 0px;
    width: 100vw;
  }

  @media screen and (min-width: 425px) {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 0px;
    width: 100vw;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 30px;
    width: 100vw;
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 35px;
    width: 100vw;
  }

  @media screen and (min-width: 1440px) {
    display: flex;
    position: relative;
    flex-direction: row;
    gap: 200px;
    justify-content: space-between;
    width: 100%;
  }

  @media screen and (min-width: 2560px) {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
  }
`;

const LeftComponent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 42px;
  width: 15%;

  @media screen and (max-width: 425px) {
    padding-left: 0px;
    width: 34%;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    padding-left: 0px;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    padding-left: 9px;
    align-items: center;
  }
`;

const LogoTextComponent = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
`


const LogoText = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.blackWhite};

  @media screen and (max-width: 425px) {
    padding-bottom: 60px;
  }
`;

const PsysDescription = styled.p`
  display: flex;
  flex-direction: row;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.blackWhite};

  @media screen and (max-width: 1440px) {
    display: none;
  }
`;

const MiddleComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 120px;

  @media screen and (max-width: 425px) {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }

  @media screen and (max-width: 514px) {
    display: flex;
    position: relative;
    flex-direction: row;
    gap: 63px;
    align-items: flex-start;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    position: relative;
    flex-direction: row;
    gap: 70px;
    align-items: flex-start;
  }
`;

const GroupOne = styled.div`
  display: flex;
  flex-direction: column;

`

const GroupTwo = styled.div`
  display: flex;
  flex-direction: column;

`

const GroupThree = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 375px) {
    display: none;
  }


`

const Links = styled.a`
  display: flex;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  color: ${({ theme }) => theme.blackWhite};

  :hover {
    color: ${({ theme }) => theme.cyanPurple};
  }
`;

const RightComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  color: ${({ theme }) => theme.blackWhite};  
  margin: 0;
  padding-left: 100px;
  

  @media screen and (max-width: 425px) {
    padding-top: 100px;
    padding-left: 0;
  }

  @media screen and (max-width: 768px) {
    padding-left: 0px;
  }

  @media screen and (max-width: 1024px) {
    padding-left: 0px;
  }

  @media screen and (min-width: 1440px) {
    padding-right: 35px;
  }

  @media screen and (min-width: 2560px) {
    padding-right: 35px;
  }
`;

const SocialLogos = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  gap: 20px;
`;

const IconTelegram = styled(FaTelegramPlane)`
  padding-left: 5px;
  width: 23px;
  height: 23px;
  color: ${({ theme }) => theme.cyanPurple};
`;

const IconDiscord = styled(SiDiscord)`
  padding-left: 5px;
  width: 23px;
  height: 23px;
  color: ${({ theme }) => theme.cyanPurple};
`;

const IconGithub = styled(RiGithubFill)`
  padding-left: 5px;
  width: 23px;
  height: 23px;
  color: ${({ theme }) => theme.cyanPurple};
`;

const IconTwitter = styled(AiOutlineTwitter)`
  padding-left: 5px;
  width: 23px;
  height: 23px;
  color: ${({ theme }) => theme.cyanPurple};
`;

const Polling = styled.div`
  display: flex;
  align-items: baseline;  
  left: 0;
  bottom: 0;
  padding: 1rem;
  color: white;
  opacity: 1;
  transition: opacity 0.25s ease;
  :hover {
    opacity: 0.9;
  }
`;

const PollingDot = styled.div`

  width: 8px;
  height: 8px;
  min-height: 8px;
  min-width: 8px;
  margin-right: 0.5rem;
  margin-top: 3px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.green1};
`;

const Time = styled.h1`
    display: flex;
    font-weight: 400;
    font-size: 12px;
    color: ${({ theme }) => theme.blackWhite};

`

export function FooterPage() {
  const [isDark, toggleDarkMode] = useDarkModeManager();
  const seconds = useSessionStart();

  return (
    <FooterContainer>
      <LeftComponent>
        <LogoTextComponent>
          <img
            src={require(isDark
              ? "../../assets/logo_pegasys_transparent.svg"
              : "../../assets/PsysWhite.png")}
            alt="loading-icon"
            width="50"
            height="50"
          />
          <LogoText>Pegasys</LogoText>
        </LogoTextComponent>
        <PsysDescription>Swap, earn, and build with the leading decentralized crypto trading protocol on Syscoin.</PsysDescription>
      </LeftComponent>
      <MiddleComponent>
        <GroupOne>
          <Links href="https://pegasys.finance/about/" target="_blank">About</Links>
          <Links href="https://pegasys.finance/" target="_blank">Pegasys.finance</Links>
          <Links href="https://docs.pegasys.finance/" target="_blank">Docs</Links>
        </GroupOne>
        <GroupTwo>
          <Links>Terms</Links>
          <Links>Trademarks</Links>
          <Links>Disclaimer</Links>
          <Links href="https://pegasys.finance/about/#brand" target="_blank">Brand</Links>
        </GroupTwo>
        <GroupThree>
          <Links href="https://app.pegasys.finance/#/swap" target="_blank">Swap</Links>
          <Links href="https://app.pegasys.finance/#/pool" target="_blank">Pool</Links>
          <Links href="https://app.pegasys.finance/#/farm" target="_blank">Farm</Links>
          <Links href="https://app.pegasys.finance/#/stake" target="_blank">Stake</Links>
        </GroupThree>
      </MiddleComponent>
      <RightComponent>
        <SocialLogos>
          <IconTelegram />
          <IconTwitter />
          <IconDiscord />
          <IconGithub />
        </SocialLogos>
        <Polling style={{ marginLeft: '.5rem' }}>
          <PollingDot />
          <a href="/" style={{ color: 'white' }}>
            <Time color={'white'}>
              Updated {!!seconds ? seconds + 's' : '-'} ago <br />
            </Time>
          </a>
        </Polling>
      </RightComponent>
    </FooterContainer>
  )
}