import styled from "styled-components";

export const Button = styled.div`
  position: fixed;
  right: 3%;
  border-radius: 99px;
  bottom: 40px;
  z-index: 99999;
  cursor: pointer;
  background-color: ${({ theme }) => theme.backgroundScrollToTop};
  color: white;

  @media (max-width: 580px) {
    right: 5%;
  }

  @media (max-width: 1440px) {
    right: 3%;
  }
`;
