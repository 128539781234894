import React, { useState } from "react";
import styled from "styled-components";
import { useDarkModeManager } from "../../contexts/LocalStorage";


const Container = styled.div`
  display: flex;
    position: absolute;
  z-index: 1 !important;
`;


export function BackgroundStars({ history }) {
    const [darkMode] = useDarkModeManager();
    return (
        <Container>
            {darkMode ? (
                <>
                    <div id="starsLightMode"></div>
                    <div id="starsLightMode2"></div>
                    <div id="starsLightMode3"></div>
                </>
            ) : (
                <>
                    <div id="stars"></div>
                    <div id="stars2"></div>
                    <div id="stars3"></div>
                </>
            )}
        </Container>
    );
}