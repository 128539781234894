import React, { useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { RowBetween, RowFixed } from "../Row";
import { AutoColumn } from "../Column";
import { TYPE } from "../../Theme";
import { useSavedTokens, useSavedPairs } from "../../contexts/LocalStorage";
import { Hover } from "..";
import TokenLogo from "../TokenLogo";
import AccountSearch from "../AccountSearch";
import { Bookmark, ChevronRight, X } from "react-feather";
import { ButtonFaded } from "../ButtonStyled";
import FormattedName from "../FormattedName";
import { useOnClickOutside } from "../../hooks";

const RightColumn = styled.div`
  position: ${({ open }) => (open ? "fixed" : "relative")};
  padding: ${({ open }) => (open ? "1.25rem" : "0")};
  top: ${({ open }) => (open ? "0" : "0")};
  right: ${({ open }) => (open ? "0" : "10px")};
  height: ${({ open }) => (open ? "100vh" : "2vh")};
  border-left: ${({ theme, open }) =>
    open ? "1px solid" + theme.pinnedBorderColor : "none"};
  border-top: ${({ theme, open }) =>
    open ? "1px solid" + theme.pinnedBorderColor : "none"};
  border-bottom: ${({ theme, open }) =>
    open ? "1px solid" + theme.pinnedBorderColor : "none"};
  z-index: 9999;
  background: ${({ theme, open }) =>
    open ? theme.drawerBackgroundColor : "none"};
`;

const SavedButton = styled(RowBetween)`
  padding-bottom: ${({ open }) => open && "20px"};
  border-bottom: ${({ theme, open }) => open && "1px solid" + theme.bg3};
  margin-bottom: ${({ open }) => open && "1.25rem"};

  :hover {
    cursor: pointer;
  }
`;

const ScrollableDiv = styled(AutoColumn)`
  overflow: auto;
  padding-bottom: 60px;
`;

const StyledIcon = styled.div`
  color: ${({ theme }) => theme.blackWhite};
`;

function PinnedData({ history, open, setSavedOpen }) {
  const [savedPairs, , removePair] = useSavedPairs();
  const [savedTokens, , removeToken] = useSavedTokens();
  const [setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setSavedOpen(false));

  return !open ? (
    <RightColumn open={open}>
      <SavedButton open={open}>
        <StyledIcon>
          <Bookmark size={22} onClick={() => setSavedOpen(true)} />
        </StyledIcon>
      </SavedButton>
    </RightColumn>
  ) : (
    <RightColumn gap="1rem" open={open} ref={node}>
      <SavedButton onClick={() => setSavedOpen(false)} open={open}>
        <RowFixed>
          <StyledIcon>
            <Bookmark size={16} />
          </StyledIcon>
          <TYPE.main ml={"4px"}>Saved</TYPE.main>
        </RowFixed>
        <StyledIcon>
          <ChevronRight />
        </StyledIcon>
      </SavedButton>
      <AccountSearch small={true} />
      <AutoColumn gap="40px" style={{ marginTop: "2rem" }}>
        <AutoColumn gap={"12px"}>
          <TYPE.main>Pinned Pairs</TYPE.main>
          {Object.keys(savedPairs).filter((key) => {
            return !!savedPairs[key];
          }).length > 0 ? (
            Object.keys(savedPairs)
              .filter((address) => {
                return !!savedPairs[address];
              })
              .map((address) => {
                const pair = savedPairs[address];
                return (
                  <RowBetween key={pair.address}>
                    <ButtonFaded
                      onClick={() => history.push("/pair/" + address)}
                    >
                      <RowFixed>
                        <TYPE.header>
                          <FormattedName
                            text={pair.token0Symbol + "/" + pair.token1Symbol}
                            maxCharacters={12}
                            fontSize={"12px"}
                          />
                        </TYPE.header>
                      </RowFixed>
                    </ButtonFaded>
                    <Hover onClick={() => removePair(pair.address)}>
                      <StyledIcon>
                        <X size={16} />
                      </StyledIcon>
                    </Hover>
                  </RowBetween>
                );
              })
          ) : (
            <TYPE.light>Pinned pairs will appear here.</TYPE.light>
          )}
        </AutoColumn>
        <ScrollableDiv gap={"12px"}>
          <TYPE.main>Pinned Tokens</TYPE.main>
          {Object.keys(savedTokens).filter((key) => {
            return !!savedTokens[key];
          }).length > 0 ? (
            Object.keys(savedTokens)
              .filter((address) => {
                return !!savedTokens[address];
              })
              .map((address) => {
                const token = savedTokens[address];
                return (
                  <RowBetween key={address}>
                    <ButtonFaded
                      onClick={() => history.push("/token/" + address)}
                    >
                      <RowFixed>
                        <TokenLogo address={address} size={"14px"} />
                        <TYPE.header ml={"6px"}>
                          <FormattedName
                            text={token.symbol}
                            maxCharacters={12}
                            fontSize={"12px"}
                          />
                        </TYPE.header>
                      </RowFixed>
                    </ButtonFaded>
                    <Hover onClick={() => removeToken(address)}>
                      <StyledIcon>
                        <X size={16} />
                      </StyledIcon>
                    </Hover>
                  </RowBetween>
                );
              })
          ) : (
            <TYPE.light>Pinned tokens will appear here.</TYPE.light>
          )}
        </ScrollableDiv>
      </AutoColumn>
    </RightColumn>
  );
}

export default withRouter(PinnedData);
