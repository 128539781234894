import React, { useState } from "react";
import styled from "styled-components";
import { ApolloProvider } from "react-apollo";
import { client } from "./apollo/client";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import GlobalPage from "./pages/GlobalPage";
import TokenPage from "./pages/TokenPage";
import PairPage from "./pages/PairPage";
import { useGlobalData, useGlobalChartData } from "./contexts/GlobalData";
import { isAddress } from "./utils";
import AccountPage from "./pages/AccountPage";
import AllTokensPage from "./pages/AllTokensPage";
import AllPairsPage from "./pages/AllPairsPage";
import PinnedData from "./components/PinnedData";

import SideNav from "./components/SideNav";
import AccountLookup from "./pages/AccountLookup";
import LocalLoader from "./components/LocalLoader";
import { useLatestBlocks } from "./contexts/Application";
import GoogleAnalyticsReporter from "./components/analytics/GoogleAnalyticsReporter";
import { PAIR_BLACKLIST, TOKEN_BLACKLIST } from "./constants";
import { useDarkModeManager } from "./contexts/LocalStorage";
import "./backgroundStars.css";
import { Header } from "./components/Header";
import { FooterPage } from "./components/FooterPage";
import { BackgroundBlur } from "./components/BackgroundBlur";
import { ThemedBackground } from "./Theme";
import { transparentize } from "polished";
import { BackgroundStars } from "./components/BackgroundStars";
import ScrollButton from "../src/components/ScrollButton";

const AppWrapper = styled.div`
  position: relative;
  width: 100%;
`;
const ContentWrapper = styled.div`
  display: grid;
  z-index: 99;
  padding-top: 50px;
  grid-template-columns: ${({ open }) =>
    open ? "220px 1fr 200px" : "220px 1fr 64px"};

  @media screen and (max-width: 1400px) {
    grid-template-columns: 220px 1fr;
  }

  @media screen and (max-width: 1080px) {
    grid-template-columns: 1fr;
    max-width: 100vw;
    overflow: hidden;
    grid-gap: 0;
  }
`;

const Right = styled.div`
  position: fixed;
  right: 0;
  bottom: 0rem;
  z-index: 99;
  width: ${({ open }) => (open ? "220px" : "64px")};
  height: ${({ open }) => (open ? "fit-content" : "64px")};
  overflow: auto;
  background-color: ${({ theme }) => theme.bg1};
  @media screen and (max-width: 1400px) {
    display: none;
  }
`;

const Center = styled.div`
  height: 100%;
  width: 99vw;
  z-index: 999;
  transition: width 0.25s ease;
  background-color: ${({ theme }) => theme.onlyLight};
`;

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const WarningBanner = styled.div`
  background-color: #ff6871;
  padding: 1.5rem;
  color: white;
  width: 100%;
  text-align: center;
  font-weight: 500;
`;

/**
 * Wrap the component with the header and sidebar pinned tab
 */
const LayoutWrapper = ({ children, savedOpen, setSavedOpen }) => {
  return (
    <>
      <Header savedOpen={savedOpen} setSavedOpen={setSavedOpen}/>
      <ContentWrapper>
        <BackgroundStars />
        <BackgroundBlur />
        <Center id="center">{children}</Center>
      </ContentWrapper>
      <FooterPage />
      <ScrollButton />
    </>
  );
};

const LayoutBackgroundStars = () => {
  const [darkMode] = useDarkModeManager();
  return (
    <>
      {darkMode ? (
        <>
          <div id="starsLightMode"></div>
          <div id="starsLightMode2"></div>
          <div id="starsLightMode3"></div>
        </>
      ) : (
        <>
          <div id="stars"></div>
          <div id="stars2"></div>
          <div id="stars3"></div>
        </>
      )}
    </>
  );
};

const BLOCK_DIFFERENCE_THRESHOLD = 30;

function App() {
  const [savedOpen, setSavedOpen] = useState(false);

  const globalData = useGlobalData();
  const globalChartData = useGlobalChartData();
  const [latestBlock, headBlock] = useLatestBlocks();

  // show warning
  const showWarning =
    headBlock && latestBlock
      ? headBlock - latestBlock > BLOCK_DIFFERENCE_THRESHOLD
      : false;

  return (
    <ApolloProvider client={client}>
      <AppWrapper>
        {showWarning && (
          <WarningWrapper>
            <WarningBanner>
              {`Warning: The data on this site has only synced to Syscoin block ${latestBlock} (out of ${headBlock}). Please check back soon.`}
            </WarningBanner>
          </WarningWrapper>
        )}
        {globalData &&
        Object.keys(globalData).length > 0 &&
        globalChartData &&
        Object.keys(globalChartData).length > 0 ? (
          <BrowserRouter>
            <Route component={GoogleAnalyticsReporter} />
            <Switch>
              <Route
                exacts
                strict
                path="/token/:tokenAddress"
                render={({ match }) => {
                  if (
                    isAddress(match.params.tokenAddress.toLowerCase()) &&
                    !Object.keys(TOKEN_BLACKLIST).includes(
                      match.params.tokenAddress.toLowerCase()
                    )
                  ) {
                    return (
                      <LayoutWrapper
                        savedOpen={savedOpen}
                        setSavedOpen={setSavedOpen}
                      >
                        <TokenPage
                          address={match.params.tokenAddress.toLowerCase()}
                        />
                        <BackgroundStars />
                      </LayoutWrapper>
                    );
                  } else {
                    return <Redirect to="/home" />;
                  }
                }}
              />
              <Route
                exacts
                strict
                path="/pair/:pairAddress"
                render={({ match }) => {
                  if (
                    isAddress(match.params.pairAddress.toLowerCase()) &&
                    !Object.keys(PAIR_BLACKLIST).includes(
                      match.params.pairAddress.toLowerCase()
                    )
                  ) {
                    return (
                      <LayoutWrapper
                        savedOpen={savedOpen}
                        setSavedOpen={setSavedOpen}
                      >
                        <BackgroundStars />
                        <PairPage
                          pairAddress={match.params.pairAddress.toLowerCase()}
                        />
                      </LayoutWrapper>
                    );
                  } else {
                    return <Redirect to="/home" />;
                  }
                }}
              />
              <Route
                exacts
                strict
                path="/account/:accountAddress"
                render={({ match }) => {
                  if (isAddress(match.params.accountAddress.toLowerCase())) {
                    return (
                      <LayoutWrapper
                        savedOpen={savedOpen}
                        setSavedOpen={setSavedOpen}
                      >
                        <BackgroundStars />
                        <AccountPage
                          account={match.params.accountAddress.toLowerCase()}
                        />
                      </LayoutWrapper>
                    );
                  } else {
                    return <Redirect to="/home" />;
                  }
                }}
              />

              <Route path="/home">
                <LayoutWrapper
                  savedOpen={savedOpen}
                  setSavedOpen={setSavedOpen}
                >
                  <BackgroundStars />
                  <GlobalPage />
                </LayoutWrapper>
              </Route>

              <Route path="/tokens">
                <LayoutWrapper
                  savedOpen={savedOpen}
                  setSavedOpen={setSavedOpen}
                >
                  <AllTokensPage />
                </LayoutWrapper>
              </Route>

              <Route path="/pairs">
                <LayoutWrapper
                  savedOpen={savedOpen}
                  setSavedOpen={setSavedOpen}
                >
                  <BackgroundStars />
                  <AllPairsPage />
                </LayoutWrapper>
              </Route>

              <Route path="/accounts">
                <LayoutWrapper
                  savedOpen={savedOpen}
                  setSavedOpen={setSavedOpen}
                >
                  <BackgroundStars />
                  <AccountLookup />
                </LayoutWrapper>
              </Route>

              <Redirect to="/home" />
            </Switch>
          </BrowserRouter>
        ) : (
          <>
            <LayoutBackgroundStars />
            <LocalLoader fill="true" />
          </>
        )}
      </AppWrapper>
    </ApolloProvider>
  );
}

export default App;
